import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "jhfp-22282.firebaseapp.com",
  projectId: "jhfp-22282",
  storageBucket: "jhfp-22282.appspot.com",
  messagingSenderId: "987391782683",
  appId: "1:987391782683:web:9a43c114a171060b012dbe",
  measurementId: "G-D48817NV59"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
