import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import RequestPopUp from '../buttons/RequestPopUp';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';

const DataTable = () => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    // LISTEN (REALTIME)
    const sub = onSnapshot(
      collection(db, "seekers"),
      (snapShot) => {
        let list = snapShot.docs.map(doc => {
          return { ...doc.data(), id: doc.id, requestJobSeekerData: <RequestPopUp seekerId={doc.id}/> }
        })
        setData(list);
        setIsLoadingData(false)
      },
      (error) => {
        console.log('error');
      }
    );

    return sub;
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row.createdAt.toDate().toDateString()}`,
        id: 'createdAt',
        header: 'Date created',
      },
      {
        header: 'Field',
        accessorKey: 'fieldOfWork',
      },
      {
        header: 'Name',
        accessorKey: 'fullName',
      },
      {
        header: 'LinkedIn Profile',
        accessorKey: 'linkedinProfile',
        accessorFn: (row) => {
          const usernameWithSlash = row.linkedinProfile.match(/linkedin\.com\/in\/(.+)/i)[1];
          const username = usernameWithSlash.replace(/\/$/, '');
          return (
            <a href={`https://www.linkedin.com/in/${username}`} target="_blank" rel="noreferrer">
              {username}
            </a>
          );
        },
      },
      {
        header: 'Looking for position(s)',
        accessorKey: 'lookingForPositions',
      },
      {
        header: 'Last Occupation',
        accessorKey: 'currentOccupation',
      },
      {
        header: 'Last Company',
        accessorKey: 'lastCompanyWorkedAt',
      },
      {
        header: 'Technical Positions',
        accessorKey: 'technicalPositionsOnly',
      },
      {
        header: 'Field of Education',
        accessorKey: 'education',
      },
      {
        header: 'Experience',
        accessorKey: 'experience',
      },
    
      {
        header: 'Skills, Technologies and Things Excelled In',
        accessorKey: 'skillsAndTechnologies',
      },
      {
        header: 'Request Job Seeker Data',
        accessorKey: 'requestJobSeekerData',
      },
    ],
    [],
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableGrouping
      enableStickyHeader
      enableStickyFooter
      enableColumnResizing
      enablePinning
      initialState={{
        density: 'compact',
        expanded: true, //expand all groups by default
        pagination: { pageIndex: 0, pageSize: 20 },
        sorting: [{ id: 'createdAt', desc: false }], //sort by state by default
        isLoading: isLoadingData,
        columnPinning: { right: ['requestJobSeekerData'] }
      }}
      state={{
        isLoading: isLoadingData,
      }}
      defaultColumn={{
        size: 300,
        minSize: 200,
        maxSize: 600
      }}
      muiToolbarAlertBannerChipProps={{ color: 'primary' }}
      muiTableContainerProps={{
        sx: { maxHeight: 1200, minHeight: 400 }
      }}
      muiTableHeadCellProps={{
        align: 'center',
      }}
      muiTableBodyCellProps={{
        align: 'center',
      }}

    />
  );
};

export default DataTable;
