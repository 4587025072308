import React from 'react';
import { Typography, Toolbar, AppBar,makeStyles } from "@material-ui/core";
import Image from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    height: 50,
    width: 50,
  },
}));

export default function Navbar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <Image src={process.env.PUBLIC_URL + '/img/JHF.jpg'} className={classes.image} />
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}
