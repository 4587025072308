import React from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Field, Form, Formik } from 'formik';
import { validationSchemaForLinks } from "../yupComponent";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2), /* Reduce the padding on smaller screens */
            width: '80%', /* Set the width to 100% by default */
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1), /* Reduce the padding on smaller screens */
        },
    },
    [theme.breakpoints.up('md')]: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(6), /* Restore the default padding on larger screens */
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1), /* Restore the default padding on larger screens */
        },
    },
}));
const FullScreenDialogHelp = () => {
    const [open, setOpen] = React.useState(false);

    const typeOptions = [
        { label: 'Community', value: 'Community' },
        { label: 'Directory', value: 'Directory' },
        { label: 'Jobs Board', value: 'Jobs Board' },
        { label: 'Non-profit Organization', value: 'Non-profit Organization' },
        { label: 'Other', value: 'Other' },
    ];

    const platformOptions = [
        { label: 'Discord', value: 'Discord' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'Linkedin', value: 'Linkedin' },
        { label: 'Google sheet', value: 'Google sheet' },
        { label: 'Telegram', value: 'Telegram' },
        { label: 'Website', value: 'Website' },
        { label: 'Whatsapp', value: 'Whatsapp' },
        { label: 'Other', value: 'Other' },
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button className="button" variant="outlined" color="success" onClick={handleClickOpen}>
                Add a link +
            </Button>
            <BootstrapDialog
                fullWidth
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >

                <Formik
                    initialValues={{ fullName: '', type: '', platform: '', description: '', link: '', size: '' }}
                    validationSchema={validationSchemaForLinks}
                    onSubmit={async (values, { setSubmitting }) => {
                        // setTimeout(() => {
                        //     alert(JSON.stringify(values, null, 2));
                        //     setSubmitting(false);
                        // }, 400);
                        try {
                            await addDoc(collection(db, "links"), {
                                createdAt: serverTimestamp(),
                                fullName: values.fullName,
                                type: typeOptions.find((type) => type.value === values.type).label,
                                platform: platformOptions.find((platform) => platform.value === values.platform).label,
                                description: values.description,
                                link: values.link,
                                size: values.size

                            }).then(() => {
                                handleClose()
                            })
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form>
                            <DialogTitle onClose={handleClose}>
                                Add A Link
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid>
                                    <Grid
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Field
                                            as={TextField}
                                            sx={{ m: 2 }}
                                            fullWidth
                                            type="text"
                                            name="fullName"
                                            id="fullName"
                                            label="Full Name"
                                            value={values.fullName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.fullName && errors.fullName}
                                            helperText={touched.fullName && errors.fullName}
                                        />

                                        <Select
                                            fullWidth
                                            sx={{ m: 2 }}
                                            helperText={touched.type ? errors.type : ""}
                                            error={touched.type && Boolean(errors.type)}
                                            displayEmpty
                                            id="type"
                                            name="type"
                                            value={values.type}
                                            onChange={handleChange} >
                                            <MenuItem value="" disabled>
                                                <em>Type</em>
                                            </MenuItem>
                                            {typeOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>

                                        <Select
                                            fullWidth
                                            sx={{ m: 2 }}
                                            helperText={touched.platform ? errors.platform : ""}
                                            error={touched.platform && Boolean(errors.platform)}
                                            displayEmpty
                                            id="platform"
                                            name="platform"
                                            value={values.platform}
                                            onChange={handleChange} >
                                            <MenuItem value="" disabled>
                                                <em>Platform</em>
                                            </MenuItem>
                                            {platformOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                            ))}
                                        </Select>


                                        <Field
                                            as={TextField}
                                            sx={{ m: 2 }}
                                            fullWidth
                                            type="text"
                                            name="description"
                                            id="description"
                                            label="Description"
                                            value={values.description}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.description && errors.description}
                                            helperText={touched.description && errors.description ? errors.description : "Brief overview of the resource, who it's for and how it helps"}
                                        />
                                        <Field
                                            as={TextField}
                                            sx={{ m: 2 }}
                                            fullWidth
                                            type="text"
                                            name="link"
                                            id="link"
                                            label="link"
                                            value={values.link}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.link && errors.link}
                                            helperText={touched.link && errors.link ? errors.link : "URL Only"}
                                        />

                                        <Field
                                            as={TextField}
                                            sx={{ m: 2 }}
                                            fullWidth
                                            type="text"
                                            name="size"
                                            id="size"
                                            label="size"
                                            value={values.size}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            error={touched.size && errors.size}
                                            helperText={touched.size && errors.size ? errors.size
                                                : "For a community, chat or group, specify the number of members"}
                                        />

                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button type="submit" variant="contained" color="success" >Save</Button>
                                <Button variant="contained" color="error" onClick={handleClose}>Close</Button>
                            </DialogActions>

                        </Form>
                    )}
                </Formik>
            </BootstrapDialog>
        </div >
    );
}

export default FullScreenDialogHelp;