import React, { useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';

const DataTableHelp = () => {
  const [data, setData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    // LISTEN (REALTIME)
    const sub = onSnapshot(
      collection(db, "links"),
      (snapShot) => {
        let list = snapShot.docs.map(doc => {
          return { ...doc.data(), id: doc.id }
        })
        setData(list);
        setIsLoadingData(false)
      },
      (error) => {
        console.log('error');
      }
    );
    return sub;
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'fullName',
      },
      {
        header: 'Type',
        accessorKey: 'type',
      },
      {
        header: 'Platform',
        accessorKey: 'platform',

      },
      {
        header: 'Description',
        accessorKey: 'description',

      },
      {
        header: 'Link',
        accessorKey: 'link',
        accessorFn: (row) => <a href={row.link} target="_blank" rel="noreferrer">{row.link}</a>,

      },
      {
        header: 'Size',
        accessorKey: 'size',
      },
      {
        accessorFn: (row) => `${row.createdAt.toDate().toDateString()}`,
        header: 'Date Added',
        accessorKey: 'createdAt',

      },
    ],
    [],
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableGrouping
      enableStickyHeader
      enableStickyFooter
      enableColumnResizing
      enablePinning
      initialState={{
        density: 'compact',
        expanded: true, //expand all groups by default
        pagination: { pageIndex: 0, pageSize: 20 },
        isLoading: isLoadingData,
        sorting: [{
          id: 'createdAt',
          desc: false
        }]
      }}
      state={{
        isLoading: isLoadingData,
      }}
      defaultColumn={{
        size: 300,
        minSize: 200,
        maxSize: 500
      }}
      muiToolbarAlertBannerChipProps={{ color: 'primary' }}
      muiTableContainerProps={{
        sx: { maxHeight: 400, minHeight: 400 }
      }}
      muiTableHeadCellProps={{
        align: 'center',
      }}
      muiTableBodyCellProps={{
        align: 'center',
      }}

    />
  );
};

export default DataTableHelp;
