import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@mui/material/Select';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { CircularProgress, TextField } from "@mui/material";
import { validationSchema } from "../yupComponent";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2), /* Reduce the padding on smaller screens */
      width: '80%', /* Set the width to 100% by default */
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1), /* Reduce the padding on smaller screens */
    },
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(6), /* Restore the default padding on larger screens */
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1), /* Restore the default padding on larger screens */
    },
  },
}));


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};




const CustomizedDialogs = () => {
  const [open, setOpen] = useState(false);

  const fieldOfWorkOptions = [
    { label: 'Business Development', value: 10 },
    { label: 'C-Level & Leadership', value: 20 },
    { label: 'Compliance, Legal & Policy', value: 30 },
    { label: 'Customer Success', value: 40 },
    { label: 'Cybersecurity', value: 50 },
    { label: 'Data Analyst', value: 60 },
    { label: 'Data Science, ML & Algorithms', value: 70 },
    { label: 'DevOps', value: 80 },
    { label: 'Finance', value: 90 },
    { label: 'Hardware Engineering', value: 100 },
    { label: 'HR', value: 110 },
    { label: 'Marketing', value: 120 },
    { label: 'Operations', value: 130 },
    { label: 'Other', value: 140 },
    { label: 'Product Management', value: 150 },
    { label: 'QA', value: 160 },
    { label: 'Sales', value: 170 },
    { label: 'Software Engineering', value: 180 },
    { label: 'System Administration and IT', value: 190 },
    { label: 'UI/UX, Design & content', value: 200 },
    { label: 'Artificial Intelligence', value: 210 },
    { label: 'Blockchain', value: 220 },
    { label: 'Content Creation', value: 230 },
    { label: 'Customer Support', value: 240 },
    { label: 'Education', value: 250 },
    { label: 'Game Development', value: 260 },
    { label: 'Healthcare', value: 270 },
    { label: 'Mobile Development', value: 280 },
    { label: 'Project Management', value: 290 },
    { label: 'Real Estate', value: 300 },
    { label: 'Social Media', value: 310 },
    { label: 'Web Development', value: 320 },
    { label: 'Environmental Sustainability', value: 330 },
    { label: 'Fitness & Wellness', value: 340 },
    { label: 'Graphic Design', value: 350 },
    { label: 'Industrial Design', value: 360 },
    { label: 'Journalism & Writing', value: 370 },
    { label: 'Logistics & Supply Chain', value: 380 },
    { label: 'Manufacturing & Production', value: 390 },
    { label: 'Non-Profit & Social Impact', value: 400 },
    { label: 'Photography & Videography', value: 410 },
    { label: 'Retail & E-commerce', value: 420 },
    { label: 'Science & Research', value: 430 },
    { label: 'Transportation & Automotive', value: 440 },
    { label: 'Aerospace & Defense', value: 450 },
    { label: 'Architecture & Planning', value: 460 },
    { label: 'Art & Creative', value: 470 },
    { label: 'Consulting', value: 480 },
    { label: 'Energy & Utilities', value: 490 },
    { label: 'Hospitality & Tourism', value: 500 },
    { label: 'Media & Entertainment', value: 510 },
    { label: 'Public Relations', value: 520 },
    { label: 'Security & Public Safety', value: 530 },
    { label: 'Telecommunications', value: 540 },
    { label: 'Venture Capital & Private Equity', value: 550 },
    { label: 'Academic', value: 560 },
    { label: 'Banking & Financial Services', value: 570 },
    { label: 'Construction', value: 580 },
    { label: 'Consumer Goods & Services', value: 590 },
    { label: 'Food & Beverage', value: 600 },
    { label: 'Government & Public Administration', value: 610 },
    { label: 'Insurance', value: 620 },
    { label: 'Pharmaceuticals & Biotechnology', value: 630 },
    { label: 'Sports & Fitness', value: 640 },
    { label: 'Visual Arts & Crafts', value: 650 },
  ];

  const technicalPositionsOnlyOptions = [
    { label: 'Yes', value: 10 },
    { label: 'No', value: 20 },
  ];
  const [isLoading, setIsLoading] = useState(false); // initially set to false
  const [file, setFile] = useState();

  // function to handle file upload
  function handleFileUpload(event) {
    const file = event.currentTarget.files[0];
    setFile(file);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <Button className="button" variant="outlined" color="success" onClick={handleClickOpen}>
        Add yourself as a job seeker +
      </Button>
      <BootstrapDialog
        fullWidth
        maxWidth={"lg"}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <Formik
          initialValues={{
            fullName: '',
            linkedinProfile: '',
            fieldOfWork: '',
            lookingForPositions: '',
            currentOccupation: '',
            technicalPositionsOnly: '',
            lastCompanyWorkedAt: '',
            education: '',
            experience: '',
            skillsAndTechnologies: '',
            email: '',
            phone: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async values => {
            setIsLoading(true);
            try {
              const storageRef = ref(storage, `/resumes/${file.name}`);
              const uploadTask = uploadBytesResumable(storageRef, file);
              uploadTask.on(
                "state_changed",
                (snapshot) => {
                  // const percent = Math.round(
                  //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                  // ); // update progress
                  // setProgress(percent);
                },
                (err) => console.log(err),
                () => {
                  getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                    // setFileURL(url)
                    try {
                      await addDoc(collection(db, "seekers"), {
                        createdAt: serverTimestamp(),
                        fullName: values.fullName,
                        linkedinProfile: values.linkedinProfile,
                        fieldOfWork: fieldOfWorkOptions.find((filed) => filed.value === values.fieldOfWork).label,
                        lookingForPositions: values.lookingForPositions,
                        currentOccupation: values.currentOccupation,
                        technicalPositionsOnly: technicalPositionsOnlyOptions.find((position) => position.value === values.technicalPositionsOnly).label,
                        lastCompanyWorkedAt: values.lastCompanyWorkedAt,
                        linkToResume: url,
                        education: values.education,
                        experience: values.experience,
                        skillsAndTechnologies: values.skillsAndTechnologies,
                        email: values.email,
                        phone: values.phone
                      }).then(() => {
                        handleClose()
                      })
                    } catch (error) {
                      console.log(error)
                    }
                  });
                }
              );
            } catch (error) {
              console.log(error);
            }

            setIsLoading(false);

          }}
        >
          {({ errors, touched, values, handleChange, handleSubmit }) => (

            <Form>
              <DialogTitle
                onClose={handleClose}
              >                High Tech Job Seekers
              </DialogTitle>
              <DialogContent  dividers>
              {/* style={{ margin: '0px', padding: '5%' }} */}
                {isLoading ? <center><CircularProgress /></center> : <Grid  >
                  <Grid
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.fullName ? errors.fullName : ""}
                      error={touched.fullName && Boolean(errors.fullName)}
                      type="text"
                      id="fullName"
                      label='Full Name'
                      name="fullName"
                      value={values.fullName}
                    />
                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.linkedinProfile ? errors.linkedinProfile : ""}
                      error={touched.linkedinProfile && Boolean(errors.linkedinProfile)}
                      type="text"
                      id="linkedin-profile"
                      label='Linkedin Profile'
                      name="linkedinProfile"
                    />
                    <Select
                      fullWidth
                      sx={{ m: 2 }}
                      helperText={touched.fieldOfWork ? errors.fieldOfWork : ""}
                      error={touched.fieldOfWork && Boolean(errors.fieldOfWork)}
                      displayEmpty
                      id="fieldOfWork"
                      name="fieldOfWork"
                      value={values.fieldOfWork}
                      onChange={handleChange} >
                      <MenuItem value="" disabled>
                        <em>Field Of Work</em>
                      </MenuItem>
                      {fieldOfWorkOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.lookingForPositions ? errors.lookingForPositions : ""}
                      error={touched.lookingForPositions && Boolean(errors.lookingForPositions)}
                      type="text"
                      id="lookingForPositions"
                      label='Looking For Positions'
                      name="lookingForPositions"
                      value={values.lookingForPositions}
                      onChange={handleChange} />
                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.currentOccupation ? errors.currentOccupation : ""}
                      error={touched.currentOccupation && Boolean(errors.currentOccupation)}
                      type="text"
                      id="currentOccupation"
                      label='Last Occupation'
                      name="currentOccupation"
                      value={values.currentOccupation}
                      onChange={handleChange} />
                    <Select
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.technicalPositionsOnly ? errors.technicalPositionsOnly : ""}
                      error={touched.technicalPositionsOnly && Boolean(errors.technicalPositionsOnly)}
                      displayEmpty
                      id="technicalPositionsOnly"
                      name="technicalPositionsOnly"
                      value={values.technicalPositionsOnly}
                      onChange={handleChange} >
                      <MenuItem value="" disabled>
                        <em>Technical Positions Only</em>
                      </MenuItem>
                      {technicalPositionsOnlyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                      ))}
                    </Select>
                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.lastCompanyWorkedAt ? errors.lastCompanyWorkedAt : ""}
                      error={touched.lastCompanyWorkedAt && Boolean(errors.lastCompanyWorkedAt)}
                      type="text"
                      id="lastCompanyWorkedAt"
                      label='Last Company Worked At'
                      name="lastCompanyWorkedAt"
                      value={values.lastCompanyWorkedAt}
                      onChange={handleChange} />

                    <label style={{ margin: 20 }} htmlFor="resumeFile">Resume File:</label>
                    <Field
                      id="linkToResume"
                      name="linkToResume"
                      type="file"
                      onChange={handleFileUpload}
                      accept=".pdf,.doc,.docx"
                      style={{
                        display: 'block',
                        width: '97%',
                        padding: '15px',
                        border: 'none',
                        backgroundColor: 'green',
                        color: 'white',
                        borderRadius: '5px',
                        fontWeight: 'bold',
                        margin: '18px',
                        cursor: 'pointer',
                      }}
                    />
                    <ErrorMessage name="resumeFile" component="div" />
                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.education ? errors.education : ""}
                      error={touched.education && Boolean(errors.education)}
                      type="text"
                      label='Filed of Education'
                      id="education"
                      name="education"
                      value={values.education}
                      onChange={handleChange} />
                    <Select
                      fullWidth
                      sx={{ m: 2 }}

                      labelId="experience-label"
                      id="experience"
                      name="experience"
                      displayEmpty
                      value={values.experience}
                      onChange={handleChange}
                      error={touched.experience && Boolean(errors.experience)}
                      helperText={touched.experience ? errors.experience : ''}
                    >
                      <MenuItem value="" disabled>
                        <em>Years of Experance</em>
                      </MenuItem>
                      <MenuItem value="0-1">0-1 years</MenuItem>
                      <MenuItem value="1-3">1-3 years</MenuItem>
                      <MenuItem value="3-5">3-5 years</MenuItem>
                      <MenuItem value="5+">5+ years</MenuItem>
                    </Select>


                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.skillsAndTechnologies ? errors.skillsAndTechnologies : ""}
                      error={touched.skillsAndTechnologies && Boolean(errors.skillsAndTechnologies)}
                      type="text"
                      label='Skills, technologies and Things Excelled In'
                      id="skillsAndTechnologies"
                      name="skillsAndTechnologies"
                      value={values.skillsAndTechnologies}
                      onChange={handleChange} />


                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.email ? errors.email : ""}
                      error={touched.email && Boolean(errors.email)}
                      label='Email '
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange} />
                    <Field
                      as={TextField}
                      sx={{ m: 2 }}
                      fullWidth
                      helperText={touched.phone ? errors.phone : ""}
                      error={touched.phone && Boolean(errors.phone)}
                      type="text"
                      label='Phone '
                      id="phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange} />
                  </Grid>
                </Grid>}
              </DialogContent>
              <DialogActions>
                <Button type="submit" disabled={isLoading} variant="contained" color="success">Save</Button>
                <Button variant="contained" color="error" onClick={handleClose} >Close</Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </BootstrapDialog>
    </div>
  );
}

export default CustomizedDialogs;