import * as Yup from 'yup';


export const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Full name is required'),

  linkedinProfile: Yup.string()
    .url('Please enter a valid LinkedIn URL')
    .matches(/^https:\/\/www\.linkedin\.com\/.*$/, 'Please enter a valid LinkedIn URL')
    .required('LinkedIn URL is required'),

  fieldOfWork: Yup.number()
    .required('LinkedIn profile is required'),

  lookingForPositions: Yup.string()
    .required('Looking for positions is required'),

  currentOccupation: Yup.string()
    .required('Last occupation is required'),

  technicalPositionsOnly: Yup.number()
    .required('Technical positions only is required'),

  lastCompanyWorkedAt: Yup.string()
    .required('Last company worked at is required'),

  education: Yup.string()
    .required('Filed of education is required'),

  experience: Yup.string()
    .required('Experience is required'),

  skillsAndTechnologies: Yup.string()
    .required('Skills, technologies and things excelled in is required'),

  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  phone: Yup.string()
    .matches(/^\+?[1-9]\d{1,14}$/, 'Please enter a valid phone number')
    .required('Phone number is required')
});

export const validationSchemaForRequest = Yup.object().shape({
  fullName: Yup.string()
    .required('Full name is required'),

  companyName: Yup.string()
    .required('Company name is required'),

  positionName: Yup.string()
    .required('Position title is required'),

  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),

  phoneNumber: Yup.string()
    .matches(/^\+?[1-9]\d{1,14}$/, 'Please enter a valid phone number')
    .required('Phone number is required')
})

export const validationSchemaForLinks = Yup.object().shape({
  fullName: Yup.string().required('Full name is required'),
  type: Yup.string().required('Type is required'),
  platform: Yup.string().required('Platform is required'),
  description: Yup.string().required('Description is required'),
  link: Yup.string()
    .required('Link is required')
    .matches(/^(https?:\/\/)/i, 'Invalid URL'),
});