import React from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Grid from '@material-ui/core/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Field, Form } from 'formik';
import { validationSchemaForRequest } from "../yupComponent";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(12),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));


const RequestPopUp=({ seekerId })=> {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <div>
            <Button variant="contained" color="success" onClick={handleClickOpen}>
                request Job Seeker Data
            </Button>
            <BootstrapDialog
                fullWidth
                maxWidth={"lg"}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <Formik
                    initialValues={{
                        fullName: '',
                        companyName: '',
                        positionName: '',
                        email: '',
                        phoneNumber: '',
                    }}
                    validationSchema={validationSchemaForRequest}
                    onSubmit={async values => {
                        try {
                            await addDoc(collection(db, "request"), {
                                createdAt: serverTimestamp(),
                                seekerID: seekerId,
                                fullName: values.fullName,
                                companyName: values.companyName,
                                positionName: values.positionName,
                                email: values.email,
                                phoneNumber: values.phoneNumber,
                                status: 'waiting'
                            }).then(() => {
                                handleClose()
                            })
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                >
                    {({ errors, touched, values, handleChange }) => (
                        <Form>
                            <DialogTitle >
                                Request Seeker Data
                            </DialogTitle>
                            <DialogContent dividers>
                                <Grid>
                                    <Grid
                                        direction="column"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Field
                                            sx={{ m: 2 }}
                                            as={TextField}
                                            fullWidth
                                            helperText={touched.fullName ? errors.fullName : ""}
                                            error={touched.fullName && Boolean(errors.fullName)}
                                            type="text"
                                            id="fullName"
                                            label='Full Name:'
                                            name="fullName"
                                            value={values.fullName}
                                            onChange={handleChange} />
                                        <Field
                                            sx={{ m: 2 }}
                                            as={TextField}
                                            fullWidth
                                            helperText={touched.companyName ? errors.companyName : ""}
                                            error={touched.companyName && Boolean(errors.companyName)}
                                            displayEmpty
                                            id="companyName"
                                            name="companyName"
                                            label='Comapny Name'
                                            value={values.companyName}
                                            onChange={handleChange} />

                                        <Field
                                            sx={{ m: 2 }}
                                            fullWidth
                                            as={TextField}
                                            helperText={touched.positionName ? errors.positionName : ""}
                                            error={touched.positionName && Boolean(errors.positionName)}
                                            id="positionName"
                                            displayEmpty
                                            label='Position Titel'
                                            name="positionName"
                                            value={values.positionName}
                                            onChange={handleChange} />

                                        <Field
                                            sx={{ m: 2 }}
                                            as={TextField}
                                            fullWidth
                                            helperText={touched.email ? errors.email : ""}
                                            error={touched.email && Boolean(errors.email)}
                                            type="text"
                                            id="email"
                                            label='Email'
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange} />
                                        <Field
                                            sx={{ m: 2 }}
                                            as={TextField}
                                            fullWidth
                                            helperText={touched.phoneNumber ? errors.phoneNumber : ""}
                                            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                                            type="text"
                                            id="phoneNumber"
                                            label='Phone Number'
                                            name="phoneNumber"
                                            value={values.phoneNumber}
                                            onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions >
                                <Button type="submit" variant="contained" color="success">Send</Button>
                                <Button variant="contained" color="error" onClick={handleClose}>Close</Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </BootstrapDialog>
        </div >
    );
}

export default RequestPopUp;