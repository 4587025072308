import React from 'react';
import DataTable from '../../components/table/DataTable'
import Navbar from '../../components/navbar/Navbar'
import { Grid, Typography, Divider } from '@mui/material';
import './home.css'
import DataTableHelp from '../../components/table/DataTableHelp';
import BootstrapDialogTitle from '../../components/buttons/CustomizedDialogs';
import FullScreenDialogHelp from '../../components/buttons/FullScreenDialogHelp';

function Home() {

    return (
        <>
            <Navbar />
            <Grid
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
            >

                <div style={{ marginBottom: 20, marginTop: 20, fontSize: '300%' }}>
                    High Tech Job Seekers
                </div>
                <BootstrapDialogTitle />
                <div style={{ margin: '20px' }}>
                    <DataTable />
                </div>


                <Divider />
                <Divider />
                <div>
                    <Typography style={{ marginBottom: 15, marginTop: 15, fontSize: '300%' }}>
                        Communities and Events
                    </Typography>
                    <FullScreenDialogHelp />
                    <div style={{ margin: '20px' }}>
                        <DataTableHelp />
                    </div>
                </div>

            </Grid>
            <footer>
                <div
                    style={{
                        fontSize: "150%",
                        padding:"1%",
                        backgroundColor: "lightgray", // use backgroundColor instead of bgcolor
                        color: "gray",
                        display: "flex", // add display:flex to enable justify-content
                        justifyContent: "center", // center content horizontally
                        alignItems: "center", // center content vertically
                        textAlign: "center", // center text within the div
                    }}
                >
                    <div>
                        <span>Got questions?</span>
                        <br />
                        <a href="mailto:info@jhfoundry.org" className="footerText">
                            info@jhfoundry.org
                        </a>{" "}
                        -{" "}
                        <a href="http://www.jhfoundry.org/" className="footerText">
                            JHF
                        </a>{" "}
                        &copy; 2023
                    </div>
                </div>

            </footer>
        </>
    )
}

export default Home